@import "reset.css";

.Hide {
    display: none;
    color: rgba(0,0,0,0);
    background: rgba(0,0,0,0);
}

.Content {
    position: fixed;
    top: 0%;
    display: flex;
    height: 100%;
    width: 100%;
	background: #AFE6E8;
    color: #FFFFFF;
	text-align: center;
    font-family: 'Itim', cursive;
    overflow: auto;
}

.GridContainer {
    width: 100%;
    height: 100%;
    /* height: 500vh; sets the height of the entire scrolling flexbox */
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.Grid {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}

.GridItem {
    margin-left: auto;
    margin-right: auto;
}

#TopGridItem {
    margin: auto;
}

#BottomGridItem {
    margin: auto;
    /* margin-top: auto; */
    /* margin-bottom: 100px; */
    /* margin-bottom: auto; */
}

#LogoWaba {
    height: 0px;   
}

#LogoWabaImg {
    transform: translate3d(1.6vw,-8vw,0vw);
    width: 25vw;
}

#Logo {
	width: 60vw;
	margin-left: auto;
	margin-right: auto;
}

#Tagline {
    transform: translateY(-1.5vw);
}

#ProductsBar {
    display: flex;
    flex-direction: row;
}

.ProductItem {
    display: flex;
    flex-direction: column;
    width: 30vmax;
}

.ProductPhoto {
    width: 25vmax;
    height: 20vmax;
    margin-top: 1vw;
    margin-bottom: 1vw;
}

.ProductPhotoSkinny {
    max-width: 20vw;    
}

#ContactBar {
  font-size: 20px;
    position: relative;
    bottom: 20px;
}

#BarTop {
    display: flex;
    position: fixed;
    top: 0%;
    width: 100%;
    height: 6vw;
    z-index: 1;
}

#BarBottom {
    position: fixed;
    bottom: 0%;
    width: 100%;
    height: 5vmax;
    z-index: 1;
}

.NavBar {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: 2vmax;
    margin-top: 1vmax;
}

.NavButton {
    font-size: 2.5vmax;
    margin-top: .5vmax;
    margin-left: 1vmax;
    margin-right: 1vmax;
}

#NavLogo {
    width: 14vmax;   
    margin-left: 2vmax;
    margin-top: 1.5vmax;
    margin-right: auto;
}

.UnderlinedLink {
    text-decoration: underline;
}

.ScrollContainer {
  overflow: hidden;
}

.ScrollBackground {
  background: url("img/characters.png") repeat-x;
  height: 25vh;
  width: 5076px;
  animation: slide 150s linear infinite;
}

#Ground {
  background-color: pink;    
}

@keyframes slide{
  0%{
    transform: translate3d(0, 0, 0);
  }
  100%{
    transform: translate3d(-1692px, 0, 0);
  }
}